import { PopupModal } from "../../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
import { TextInput } from "../../../../components/TextInput";
import { SelectInput } from "../../../../components/SelectInput";
import { Button } from "../../../../components/Button";
import { useEffect, useRef, useState } from "react";
import { api } from "../../../../helpers/api";
import { TopicSelect } from "./TopicSelect";
import { fileUpload } from "../../../../helpers/fileUpload";
import { SubTopicSelect } from "./SubTopicSelect";

type PublisherType = {
  publisher_id: string;
  full_name: string;
  user_name: string;
  email_id: string;
  user_image: string | null;
  mobile: string;
};

export function CourseForm({ onClose }: { onClose: () => void }) {
  const coverImageRef = useRef<HTMLInputElement>(null);
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [courseLevels, setCourseLevels] = useState<any[]>([]);
  const [publisher, setPublisher] = useState<null | PublisherType>(null);
  const [formData, setFormData] = useState<any>({
    publisher_id: "5cce949b-d547-4929-a894-f172f647757f", // Easyed Publisher ID
    course_levels: [],
    level: "3add12a5-f231-4ae7-bf25-0265a24667d4",
    sub_video: [],
    sub_topics: [],
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const storedPublisher = localStorage.getItem("selectedPublisher");
    if (storedPublisher) {
      setPublisher(JSON.parse(storedPublisher));
    }
  }, []);

  const handleCoverImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    setCoverImage(e.target.files?.[0] || null);
  };

  const handleSubmit = async () => {
    try {
      setSaving(true);
      console.log(formData);
      const data: any = {
        ...formData,
        publisher_id: publisher
          ? publisher?.publisher_id
          : formData.publisher_id,
        course_levels: [formData?.level],
        topics: formData?.topics?.map((topic: any) => topic?.value),
        sub_topics: formData?.sub_topics?.map((topic: any) => topic?.value),
      };

      if (coverImage) {
        const { response, url } = await fileUpload(coverImage, "COURSE_COVER");
        data.thumb_image = url;
      }
      api.createPublisherVideo(data).then((res) => {
        setSaving(false);
      });
    } catch (error) {
      setSaving(false);
    }
  };

  const imagePreview = coverImage ? URL.createObjectURL(coverImage) : null;

  useEffect(() => {
    api.getCourseLevels().then((res) => {
      setCourseLevels(res?.data?.courseLevels || []);
    });
  }, []);

  const disabled =
    !formData?.title || !formData?.level || !formData?.topics || !coverImage;

  return (
    <PopupModal
      contentClassName="w-[436px] mx-auto"
      open={true}
      onClose={onClose}
      showCloseButton={false}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-lg text-black font-bold tracking-[-0.2px]">
          Add course
        </h1>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1.5">
          <label className="text-xs text-black font-medium tracking-[-0.24px]">
            <div className="opacity-80">
              <span>Cover </span>
              <span className="!text-[#444444]">
                (Recommended size 400x500)
              </span>
            </div>
          </label>
          <button
            onClick={() => {
              console.log(coverImageRef.current);
              if (coverImageRef.current) {
                coverImageRef.current.click();
              }
            }}
            className="cursor-pointer w-[84px] h-[105px] bg-[#e4ebe4] rounded-[7px] border border-dashed border-[#355e3b] flex-col justify-center items-center flex"
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="cover"
                className="w-full h-full object-cover"
              />
            ) : (
              <UploadIcon className="w-6 h-6" />
            )}
          </button>
          <input
            type="file"
            className="hidden"
            ref={coverImageRef}
            accept="image/*"
            onChange={handleCoverImageChange}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-xs text-black font-medium tracking-[-0.24px]">
            Course name
          </label>
          <TextInput
            onChange={(e) => {
              setFormData({ ...formData, title: e.target.value });
            }}
            value={formData?.title}
            name="title"
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-xs text-black font-medium tracking-[-0.24px]">
            Course Level
          </label>
          <SelectInput
            data={courseLevels?.map((level) => ({
              label: level?.name,
              value: level?.name,
            }))}
            onChange={(e) => {
              console.log(e.target.value);
              setFormData({ ...formData, level: e.target.value });
            }}
            value={formData?.level || ""}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <TopicSelect
            label="Course Topics"
            onChange={(value) => {
              console.log(value);
              setFormData({ ...formData, topics: value });
            }}
            value={formData?.topics || []}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <SubTopicSelect
            topics={formData?.topics ?? []}
            label="Course Sub-Topics"
            onChange={(value) => {
              setFormData({ ...formData, sub_topics: value });
            }}
            value={formData?.sub_topics || []}
          />
        </div>
        <div>
          <Button
            variant="primary"
            onClick={handleSubmit}
            loading={saving}
            disabled={disabled}
          >
            Next
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}
