import React, { useState, useEffect } from "react";
import FullScreenModal from "../../components/FullScreenModal";
import { Button } from "../../components/Button";
import { api } from "../../helpers/api";

type Publisher = {
  publisher_id: string;
  full_name: string;
  user_name: string;
  email_id: string;
  user_image: string | null;
  mobile: string;
};

export default function SelectCreator({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const [publishers, setPublishers] = useState<Publisher[]>([
    {
      publisher_id: "5cce949b-d547-4929-a894-f172f647757f",
      full_name: "EasyEd",
      user_name: "EasyEd Admin",
      email_id: "admin@yopmail.com",
      user_image: "",
      mobile: "",
    },
  ]);
  const [selectedPublisherId, setSelectedPublisherId] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Fetch publishers with pagination
  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        setIsLoading(true);
        try {
          const res = await api.getPublishers({ page, limit: 10 });
          const fetchedPublishers = res?.data?.publisher || [];

          // Append new data and avoid duplicates
          setPublishers((prev) => {
            const newPublishers = [
              ...prev,
              ...fetchedPublishers.filter(
                (newPublisher) =>
                  !prev.some(
                    (publisher) =>
                      publisher.publisher_id === newPublisher.publisher_id
                  )
              ),
            ];
            return newPublishers;
          });

          setHasMore(fetchedPublishers.length === 10);
        } catch (error) {
          console.error("Error fetching publishers:", error);
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isOpen, page]);

  useEffect(() => {
    const storedPublisher = localStorage.getItem("selectedPublisher");
    if (storedPublisher) {
      const data = JSON.parse(storedPublisher);
      setSelectedPublisherId(data?.publisher_id);
    }
  }, []);

  const handleSelect = (publisher: Publisher) => {
    localStorage.setItem("selectedPublisher", JSON.stringify(publisher));
    setSelectedPublisherId(publisher.publisher_id);
  };

  const loadMore = () => {
    if (hasMore && !isLoading) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <FullScreenModal isOpen={isOpen} onClose={handleClose}>
      <div className="p-4 max-w-[600px] mx-auto" style={{ overflowY: "auto" }}>
        <h1 className="text-2xl font-medium mb-5">Select Creator</h1>
        {isLoading && publishers.length === 0 ? (
          <p>Loading...</p>
        ) : (
          <div className="space-y-4">
            {publishers.map((publisher) => (
              <div
                key={publisher.publisher_id}
                className="flex items-center gap-4 p-4 bg-white shadow rounded-lg"
              >
                <img
                  src={
                    publisher.user_image ||
                    "https://via.placeholder.com/50?text=No+Image"
                  }
                  alt={publisher.full_name}
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex-1">
                  <p className="font-semibold text-lg">{publisher.full_name}</p>
                  <p className="text-sm text-gray-600">{publisher.email_id}</p>
                  <p className="text-sm text-gray-600">{publisher.mobile}</p>
                </div>
                <Button
                  variant="outline"
                  className="text-sm"
                  disabled={selectedPublisherId === publisher.publisher_id}
                  onClick={() => handleSelect(publisher)}
                >
                  {selectedPublisherId === publisher.publisher_id
                    ? "Selected"
                    : "Select"}
                </Button>
              </div>
            ))}
            {isLoading && (
              <div className="text-center mt-4">
                <Button variant="primary" disabled>
                  Loading...
                </Button>
              </div>
            )}
            {!isLoading && hasMore && (
              <div className="text-center mt-4">
                <Button variant="primary" onClick={loadMore}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </FullScreenModal>
  );
}
