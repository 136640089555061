import { useEffect, useState } from "react";
import { VideoForm } from "./VideoForm";
import { api } from "../../../../helpers/api";
import { ShowToast } from "../../../../components/toast";

export function TrailerVideo({
  data,
  onUpdate,
}: {
  data: any;
  onUpdate: () => void;
}) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState<any>({});
  const [saving, setSaving] = useState(false);

  const onEdit = () => {
    setEdit(true);
  };

  const onDelete = () => {
    setDeleteConfirm(true);
  };

  const onChange = (data: any) => {
    setForm(data);
  };
  console.log(form);
  const handleSave = async () => {
    try {
      setSaving(true);
      const res = await api.updatePublisherVideo(data?.video_id, {
        ...data,
        short_video: form?.url,
        trailer_title: form?.title,
        trailer_thumb_image: form?.thumb_image,
        video_id: data?.video_id,
        duration: Math.round(form?.duration * 1000),
      });
      setSaving(false);
      if ([200, 201].includes(res?.status)) {
        onUpdate();
        setEdit(false);
      }
    } catch (error) {
      setSaving(false);
      ShowToast({
        message: "Failed to update trailer video",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setForm({
      url: data?.short_video,
      title: data?.trailer_title,
      thumb_image: data?.trailer_thumb_image,
    });
  }, [data]);

  //   if (edit || !data?.short_video) {
  //     return (
  //       <VideoForm
  //         data={form}
  //         onChange={onChange}
  //         onSave={handleSave}
  //         loading={saving}
  //       />
  //     );
  //   }
  return (
    <div className="mb-6">
      <div className="text-black text-lg font-bold font-['Montserrat'] mb-3">
        Trailer Video
      </div>
      {edit || !data?.short_video ? (
        <>
          <VideoForm
            data={form}
            onChange={onChange}
            onSave={handleSave}
            loading={saving}
          />
        </>
      ) : (
        <>
          <div className="flex items-stretch gap-1.5 pt-3 noselect z-[99]">
            <div className="pl-3 w-full pr-[21px] py-3 bg-white rounded-xl border border-[#e4ebe4] justify-start items-center gap-4 inline-flex">
              <img
                className="w-[72px] h-[72px] rounded-lg"
                src={data?.trailer_thumb_image}
                alt="banner-img"
              />
              <div className="grow shrink basis-0 flex-col justify-center items-start gap-1.5 inline-flex">
                <div className="self-stretch text-black text-[15px] font-semibold font-['Montserrat'] leading-snug">
                  {data?.trailer_title}
                </div>
              </div>
              <div className="flex-col justify-center items-end gap-[9px] inline-flex">
                <button
                  onClick={() => onEdit()}
                  className="text-[#355e3b] text-sm font-medium font-['Montserrat']"
                >
                  Edit
                </button>
                <button
                  onClick={() => onDelete()}
                  className="text-[#f54242] text-sm font-medium font-['Montserrat']"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
